import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import Page from "src/components/Page";
import StatCard from "src/components/StatCard";
import { getEvents } from "src/repos/events";
import { getDeviceMaintenances } from "src/repos/maintenances";
import {
  getDeviceDowntimes,
  getDeviceTimeseries,
  getSingleMetricAverageOverTimeRange,
} from "src/repos/devices";
import { isAllowed } from "src/utils/tier_permissions";
import Events from "./Events";
import Maintenance from "./Maintenance";
import moment from "moment";
import MachineHealth from "./MachineHealth";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  container: {
    padding: 0,
  },
  gridItem: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 20%",
    },
    [theme.breakpoints.down("md")]: {
      flex: "0 0 50%",
    },
  },
}));

function Overview({ machine }) {
  const classes = useStyles();
  const machineId = machine.id;
  const service_tier = machine?.service_tier;
  const [maintenances, setMaintenances] = useState([]);
  const [events, setEvents] = useState([]);
  const [maintenanceIsLoading, setMaintenanceIsLoading] = useState([true]);
  const [eventsIsLoading, setEventsIsLoading] = useState([true]);
  const [oee, setOee] = useState(null);
  const [uptime, setUptime] = useState(null);
  const utc_now = useMemo(() => moment.utc().format(), []);
  const four_hours_ago = useMemo(
    () => moment.utc().subtract(4, "hours").format(),
    []
  );
  const one_week_ago = useMemo(() => moment.utc().subtract(7, "days"), []);
  const [fleetAggregate, setFleetAggregate] = useState({});
  const [metricsAggregate, setMetricsAggregate] = useState({});
  const [downtime, setDowntime] = useState({});
  const [downtimeLoading, setDowntimeLoading] = useState(true);
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    if (!_.isEmpty(machine)) {
      getDeviceMaintenances(machine.id)
        .then((r) => r.json())
        .then(({ maintenances }) => setMaintenances(maintenances))
        .then(() => setMaintenanceIsLoading(false));
      getEvents(machine.id, one_week_ago)
        .then((r) => r.json())
        .then(({ data: events }) => setEvents(events))
        .then(() => setEventsIsLoading(false));
      getSingleMetricAverageOverTimeRange(
        machine.id,
        "operating_productivity",
        four_hours_ago,
        utc_now
      )
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            const val = data.data.metric_averages.value;
            setOee(val ? _.round(val * 100, 1) : val);
          }
        });
      getSingleMetricAverageOverTimeRange(
        machine.id,
        "machine_running",
        four_hours_ago,
        utc_now
      )
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            const val = data.data.metric_averages.value;
            setUptime(val ? _.round(val * 100, 1) : val);
          }
        })
        .catch(() => setOee(null));
    }
  }, [machine, four_hours_ago, utc_now, one_week_ago]);

  useEffect(() => {
    if (machine?.id) {
      getDeviceTimeseries({
        devices: [machine.id],
        metrics: ["operating_productivity", "machine_running"],
        start_date: four_hours_ago,
        end_date: utc_now,
        datapoints: 4,
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setFleetAggregate(data.data);
          }
        })
        .catch(() => setFleetAggregate({}));
    }
  }, [machine, four_hours_ago, utc_now]);

  useEffect(() => {
    if (machine?.id) {
      setDowntimeLoading(true);
      getDeviceDowntimes([machine.id])
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setDowntime(data.data[machine.id]);
          }
          setDowntimeLoading(false);
        })
        .catch(() => {
          setDowntime({});
          setDowntimeLoading(false);
        });
    }
  }, [machine]);

  useEffect(() => {
    if (machine?.id) {
      getDeviceTimeseries({
        devices: [machine.id],
        metrics: ["sheets_per_hour", "machine_speed", "msf_hr"],
        start_date: four_hours_ago,
        end_date: utc_now,
        datapoints: 4,
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            setMetricsAggregate(data.data);
          }
        })
        .catch(() => setMetricsAggregate({}));
    }
  }, [machine, four_hours_ago, utc_now]);

  return (
    <Page className={classes.root} title="Overview">
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <StatCard
              telemetry="sheets_per_hour"
              machineId={machineId}
              label={t("throughput")}
              units={t("sheets/hr")}
              chartData={metricsAggregate?.sheets_per_hour}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <StatCard
              telemetry="machine_speed"
              machineId={machineId}
              label={t("machine_speed")}
              units={t("RPM")}
              chartData={metricsAggregate?.machine_speed}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <StatCard
              telemetry="msf_hr"
              machineId={machineId}
              label={t("msf")}
              units={t("per/hr")}
              chartData={Math.round(metricsAggregate?.msf_hr)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <MachineHealth
              downtimeLoading={downtimeLoading}
              opData={oee}
              uptimeData={uptime}
              chartData={fleetAggregate}
              downtimeData={downtime}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item lg={6} sm={12} xs={12}>
            <Maintenance
              isLoading={maintenanceIsLoading}
              table_name={t("maintenance")}
              maintenance_list={maintenances}
              style={{ height: 500 }}
            />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Events
              isLoading={eventsIsLoading}
              style={{ height: 500 }}
              events={events}
              machine={machine}
              isAllowed={
                _.isNil(service_tier) || isAllowed("accessEvents", service_tier)
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

Overview.propTypes = {
  machine: PropTypes.object,
};

Overview.defaultProps = {
  machine: {},
};

export default Overview;
